import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { StaticModule } from './static/static.module';

import { AppComponent } from './app.component';
import { NavigationComponent } from './global/navigation/navigation.component';
import { WaterfallTransitionComponent } from './global/waterfall-transition/waterfall-transition.component';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    WaterfallTransitionComponent,
  ],
  imports: [BrowserModule, AppRoutingModule, StaticModule.forRoot()],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
