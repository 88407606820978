import { Injectable, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WaterfallTransitionService {
  updateCurtainState$ = new Subject();
  armViewEasing$ = new Subject();

  isTransitioning = false;
  isTransitioning$ = new Subject();

  isExpectingNavigationEnd = false;
  navigationEndSubscription = new Subscription();

  constructor(private router: Router, private zone: NgZone) {
    this.navigationEndSubscription = router.events.subscribe((e) => {
      if (e instanceof NavigationEnd && this.isExpectingNavigationEnd) {
        // We are not expecting navigation end anymore;
        this.isExpectingNavigationEnd = false;

        // Dive Curtain;
        this.diveCurtain(() => {
          // Disarm View Easing;
          this.armViewEasing$.next(false);

          // Broadcast that we are NOT transitioning;
          this.isTransitioning = false;
        });
      }
    });
  }

  transitionRoute(target: string, callback?: () => void) {
    // Block transition if we are in the same url;
    if (target === this.router.url) {
      return;
    }

    // Make sure we're not transitioning;
    if (!this.isTransitioning) {
      // Is Expecting to end;
      this.isExpectingNavigationEnd = true;

      // Broadcast that we are transitioning;
      this.isTransitioning = true;

      // Drop curtain;
      this.dropCurtain(() => {
        // Drop Curtain Callback (if defined) runs here;
        if (callback) {
          callback!();
        }

        // Broadcast to prepare easing;
        this.armViewEasing$.next(true);

        // Programmatically Route to target page;
        this.zone.run(async () => {
          this.router.navigate([target]);
        });
      });
    }
  }

  dropCurtain(callback?: () => void) {
    let navigationInstruction = {
      command: 'drop',
      callback: callback,
    };
    this.updateCurtainState$.next(navigationInstruction);
  }

  diveCurtain(callback?: () => void) {
    let navigationInstruction = {
      command: 'dive',
      callback: callback,
    };
    this.updateCurtainState$.next(navigationInstruction);
  }
}
