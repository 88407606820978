import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { gsap } from 'gsap';
import { Subscription } from 'rxjs';
import { WaterfallTransitionService } from './waterfall-transition.service';

@Component({
  selector: 'app-curtain',
  templateUrl: './waterfall-transition.component.html',
  styleUrls: ['./waterfall-transition.component.scss'],
})
export class WaterfallTransitionComponent implements OnInit {
  @ViewChild('curtains') curtains?: any;

  WaterfallTransitionServiceSubscription: Subscription;

  constructor(
    private renderer: Renderer2,
    private WaterfallTransitionService: WaterfallTransitionService
  ) {
    // Subscribe to state calls;
    this.WaterfallTransitionServiceSubscription =
      this.WaterfallTransitionService.updateCurtainState$.subscribe(
        (navigationInstruction: any) => {
          switch (navigationInstruction.command) {
            case 'drop':
              this.dropCurtain(navigationInstruction.callback);
              break;
            case 'dive':
              this.diveCurtain(navigationInstruction.callback);
              break;
          }
        }
      );
  }

  ngOnInit(): void {}

  ngAfterViewInit() {}

  dropCurtain(callback?: () => void) {
    // Set Transform;
    this.setTransform();

    let curtains = this.curtains.nativeElement.childNodes;
    gsap.to(curtains, {
      y: 0,
      duration: 1.2,
      stagger: 0.12,
      ease: 'power3.inOut',
      onComplete: () => {
        callback!();

        // Unset Transform;
        this.unsetTransform();
      },
    });
  }

  diveCurtain(callback?: () => void) {
    // Set Transform;
    this.setTransform();

    let curtains = this.curtains.nativeElement.childNodes;
    gsap.to(curtains, {
      y: '100%',
      duration: 1.2,
      stagger: {
        amount: 0.4,
      },
      ease: 'power3.inOut',
      onComplete: () => {
        callback!();

        // Reset Curtain;
        this.resetCurtain();
      },
    });
  }

  resetCurtain() {
    let curtains = this.curtains.nativeElement.childNodes;
    gsap.set(curtains, {
      y: '-100%',
    });

    this.unsetTransform();
  }

  setTransform() {
    // Set Will Change Transform to all Transition Waterfall;
    let curtains = this.curtains.nativeElement.childNodes;
    curtains.forEach((el: ElementRef) => {
      this.renderer.setStyle(el, 'will-change', 'transform');
    });
  }

  unsetTransform() {
    // Set Will Change Transform to all Transition Waterfall;
    let curtains = this.curtains.nativeElement.childNodes;
    curtains.forEach((el: ElementRef) => {
      this.renderer.setStyle(el, 'will-change', 'unset');
    });
  }

  ngOnDestroy() {
    this.WaterfallTransitionServiceSubscription.unsubscribe();
  }
}
