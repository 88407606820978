<div class="navigation">
  <div class="navigation-bar" #navigationBar>

    <div class="global-wrapper section-padding">
      <div class="branding" (click)="brandingTransitionRoute()" [ngClass]="{
        clickable: !isActiveRoute('/home') && !navigationIsDropped
      }">
        <div class="branding-letters" #brandingLetters>
          <span>i</span>
          <span>m</span>
          <span>c</span>
          <span>o</span>
        </div>

        <div class="branding-home" #brandingHome>
          <img src="../../../assets/img/home-branding.svg" alt="Home Branding" />
        </div>
      </div>
      <div class="center-fold"></div>
      <div class="menu" (click)="navigationToggle()" #menu>
        <span class="span-a" #spanA></span>
        <span class="span-b" #spanB></span>
        <span class="span-c" #spanC></span>
      </div>

    </div>
  </div>

  <div class="navigation-body" #navigationBody [ngClass]="{ 'is-dropped': navigationIsDropped }">
    <div class="global-wrapper">
      <div class="nav-links">
        <ul #navLinks>
          <li class="nav-route" (click)="transitionRouteTo('/home')"
            [ngClass]="{ 'selected-route': isActiveRoute('/home') }">
            <span>01</span>
            <h2 class="light">Home</h2>
            <p>It's where the magic never happens!</p>
          </li>
          <li class="nav-route" (click)="transitionRouteTo('/about')"
            [ngClass]="{ 'selected-route': isActiveRoute('/about') }">
            <span>02</span>
            <h2 class="light">About Me</h2>
            <p>My story, goals and what I believe in!</p>
          </li>
          <li class="nav-route" (click)="transitionRouteTo('/portfolio')"
            [ngClass]="{ 'selected-route': isActiveRoute('/portfolio') }">
            <span>03</span>
            <h2 class="light">Portfolio</h2>
            <p>Bringing you the best curations of our work!</p>
          </li>
          <li class="nav-route" (click)="transitionRouteTo('/showcase')"
            [ngClass]="{ 'selected-route': isActiveRoute('/showcase') }">
            <span>04</span>
            <h2 class="light">Showcase</h2>
            <p>Some fun things I do on my spare time</p>
          </li>
          <li class="nav-route" (click)="transitionRouteTo('/contact')"
            [ngClass]="{ 'selected-route': isActiveRoute('/contact') }">
            <span>05</span>
            <h2 class="light">Contact Us</h2>
            <p>Reach out and let us hear you</p>
          </li>
        </ul>
        <div class="social-links" #socialLinks>
          <div class="social-link">
            <a href="#" target="_blank">
              <img src="../../../assets/img/social/facebook.svg" alt="" />
            </a>
          </div>
          <div class="social-link">
            <a href="#" target="_blank">
              <img src="../../../assets/img/social/linked-in.svg" alt="" />
            </a>
          </div>
          <div class="social-link">
            <a href="#" target="_blank">
              <img src="../../../assets/img/social/pinterest.svg" alt="" />
            </a>
          </div>
          <div class="social-link">
            <a href="#" target="_blank">
              <img src="../../../assets/img/social/instagram.svg" alt="" />
            </a>
          </div>
          <div class="social-link">
            <a href="#" target="_blank">
              <img src="../../../assets/img/social/dribble.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
      <div class="nav-footer" #navFooter>
        <p>© 2020 IMCO by Chris Javier Oliveros. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</div>
