import { gsap } from 'gsap';

export class MenuAnimations {
  flyInMenu(el: HTMLElement, callback?: () => void, delay?: number) {
    /* Setters */
    gsap.set(el.children[0], {
      x: '0px',
      y: '9px',
      rotate: '0',
      width: '0%',
      overwrite: true,
    });

    gsap.set(el.children[1], {
      x: '0',
      y: '16px',
      rotate: '0',
      width: '0%',
      overwrite: true,
    });

    gsap.set(el.children[2], {
      x: '0px',
      y: '23px',
      rotate: '0',
      width: '0%',
      overwrite: true,
    });

    /* Animators */
    let tl = gsap.timeline({
      delay: delay,
      onComplete: callback,
    });

    tl.to(el.children[0], {
      width: '80%',
      opacity: 1,
    });

    tl.to(
      el.children[1],
      {
        width: '100%',
        opacity: 1,
      },
      '-=0.3'
    );

    tl.to(
      el.children[2],
      {
        width: '60%',
        opacity: 1,
      },
      '-=0.3'
    );
  }

  flyOutMenu(el: HTMLElement, callback?: () => void, delay?: number) {
    /* Animate */
    gsap.to(el.childNodes, {
      x: '-18px',
      opacity: 0,
      duration: 0.4,
      overwrite: true,
      stagger: {
        each: 0.15,
        from: 'end',
      },
      onComplete: callback,
    });
  }

  flyInClose(el: HTMLElement, callback?: () => void, delay?: number) {
    /* Setters */
    gsap.set(el.children[0], {
      x: '-8px',
      y: '7px',
      width: '100%',
      rotate: '45deg',
      opacity: 0,
    });

    gsap.set(el.children[2], {
      x: '8px',
      y: '7px',
      width: '100%',
      rotate: '-45deg',
      opacity: 0,
    });

    let tl = gsap.timeline({
      delay: delay,
      onComplete: callback,
    });

    /* Animate */
    tl.to(el.children[0], {
      x: '1px',
      y: '16px',
      ease: 'back.out(1.3)',
      duration: 0.6,
      opacity: 1,
    });

    tl.to(
      el.children[2],
      {
        x: '1px',
        y: '16px',
        ease: 'back.out(1.3)',
        duration: 0.6,
        opacity: 1,
        delay: 0.2,
      },
      '-=0.6'
    );
  }

  flyOutClose(el: HTMLElement, callback?: () => void, delay?: number) {
    let tl = gsap.timeline({
      delay: delay,
      defaults: {
        duration: 0.4,
        delay: 0,
      },
      onComplete: callback,
    });

    /* Animate */
    tl.to(el.children[2], {
      x: '-7px',
      y: '21px',
      opacity: 0,
      ease: 'back.in(1)',
    });

    tl.to(
      el.children[0],
      {
        x: '7px',
        y: '21px',
        opacity: 0,
        ease: 'back.in(1)',
      },
      '-=0.6'
    );
  }

  forceFlyInMenu(el: HTMLElement) {
    gsap.set(el.children[0], {
      width: '80%',
      opacity: 1,
      x: '0px',
      y: '9px',
      rotate: '0',
      overwrite: true,
    });

    gsap.set(el.children[1], {
      width: '100%',
      opacity: 1,
      x: '0',
      y: '16px',
      rotate: '0',
      overwrite: true,
    });

    gsap.set(el.children[2], {
      width: '60%',
      opacity: 1,
      x: '0px',
      y: '23px',
      rotate: '0',
      overwrite: true,
    });
  }
}
