import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

/* Animation TS */
import { WaterfallTransitionService } from '../waterfall-transition/waterfall-transition.service';
import { BrandingAnimations } from './animations/branding.animation';
import { MenuAnimations } from './animations/menu.animations';
import { NavBodyAnimations } from './animations/nav-body.animation';

@Component({
  selector: 'app-navigation',
  providers: [MenuAnimations, BrandingAnimations, NavBodyAnimations],
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  @ViewChild('menu') menu?: ElementRef;
  @ViewChild('brandingLetters') brandingLetters?: ElementRef;
  @ViewChild('brandingHome') brandingHome?: ElementRef;

  @ViewChild('navigationBar') navigationBar?: ElementRef;
  @ViewChild('navigationBody') navigationBody?: ElementRef;
  @ViewChild('navLinks') navLinks?: ElementRef;
  @ViewChild('socialLinks') socialLinks?: ElementRef;
  @ViewChild('navFooter') navFooter?: ElementRef;

  navigationInteractive = false;
  navigationIsDropped = false;

  isFlyOutHome = false;
  navigationEndSubscription = new Subscription();

  constructor(
    private menuAnimations: MenuAnimations,
    private brandingAnimations: BrandingAnimations,
    private navBodyAnimations: NavBodyAnimations,
    private waterfallTransitionService: WaterfallTransitionService,
    private router: Router
  ) {
    this.navigationEndSubscription = router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        // We check if home is flown out;
        if (this.isFlyOutHome) {
          // Animate;
          this.brandingAnimations.flyOutBrandingFlyInHome(
            this.brandingHome?.nativeElement,
            this.brandingLetters?.nativeElement
          );

          // Set;
          this.isFlyOutHome = false;
        }
      }
    });
  }

  ngOnInit(): void { }

  ngAfterViewInit() {
    // Fly In Branding;
    this.brandingAnimations.flyInHome(this.brandingHome?.nativeElement);

    // Fly In Menu;
    this.menuAnimations.flyInMenu(this.menu?.nativeElement, () => {
      this.navigationInteractive = true;
    });
  }

  navigationToggle() {
    // Only Allow Menu Clicks when navigation is Interactive;
    if (this.navigationInteractive) {
      if (!this.navigationIsDropped) {
        this.launchNavigation();
      } else {
        this.unLaunchNavigation();
      }
    }
  }

  launchNavigation() {
    // 1.) Update Navigation Interactibility;
    this.navigationInteractive = false;

    // 1.a) Piggy back arm view easing;
    this.waterfallTransitionService.armViewEasing$.next(true);

    // 2.) Update Navigation State;
    this.navigationIsDropped = true;

    // Fly Out Menu;
    this.menuAnimations.flyOutMenu(this.menu?.nativeElement, () => {
      // Fly In Navigation Links
      this.navBodyAnimations.flyInNavLinks(this.navLinks?.nativeElement, () => {
        // Fly In Navigation Footer
        this.navBodyAnimations.flyInNavfooter(this.navFooter?.nativeElement);

        // Fly In Close Menu;
        this.menuAnimations.flyInClose(this.menu?.nativeElement, () => {
          // Re-enable navigation interactions;
          this.navigationInteractive = true;
        });
      });

      // Fly In Social Links;
      this.navBodyAnimations.flyInSocialLinks(
        this.socialLinks?.nativeElement,
        () => { },
        0.1
      );
    });

    /* Fly Out Home - Fly in Branding */
    this.isFlyOutHome = true;
    this.brandingAnimations.flyOutHomeFlyInBranding(
      this.brandingHome?.nativeElement,
      this.brandingLetters?.nativeElement
    );
  }

  unLaunchNavigation() {
    // Update Navigation Interactibility;
    this.navigationInteractive = false;

    // Unlaunch Footer;
    this.navBodyAnimations.flyOutNavfooter(this.navFooter?.nativeElement);

    // Fly Out Social Links;
    this.navBodyAnimations.flyOutSocialLinks(this.socialLinks?.nativeElement);

    // Fly Out Navigation Links;
    this.navBodyAnimations.flyOutNavLinks(
      this.navLinks?.nativeElement,
      () => { },
      0.15
    );

    // Fly Out Menu;
    this.menuAnimations.flyOutClose(
      this.menu?.nativeElement,
      () => {
        this.navigationIsDropped = false;

        // 1.a) Piggy back disarming view easing;
        this.waterfallTransitionService.armViewEasing$.next(false);
      },
      0.5
    );

    // Fly Out Branding - Fly In Home;
    this.brandingAnimations.flyOutBrandingFlyInHome(
      this.brandingHome?.nativeElement,
      this.brandingLetters?.nativeElement,
      0.7,
      () => {
        // Fly In Menu;
        this.menuAnimations.flyInMenu(this.menu?.nativeElement, () => {
          this.navigationInteractive = true;
        });
      }
    );
  }

  forceOutNavigation() {
    // Set Navigation Dropped to false;
    this.navigationIsDropped = false;

    // Force FlyInMenu;
    this.menuAnimations.forceFlyInMenu(this.menu?.nativeElement);

    // Force Hide Navlinks + Social Links + Footer;
    this.navBodyAnimations.forceOutNavLinks(this.navLinks?.nativeElement);
    this.navBodyAnimations.forceOutSocialLinks(this.socialLinks?.nativeElement);
    this.navBodyAnimations.forceOutNavFooter(this.navFooter?.nativeElement);
  }

  transitionRouteTo(target: string) {
    this.waterfallTransitionService.transitionRoute(target, () => {
      // Force unlaunch Navigation;
      this.forceOutNavigation();
    });
  }

  brandingTransitionRoute() {
    // Allow only if navigation is not dropped;
    if (!this.navigationIsDropped) {
      this.waterfallTransitionService.transitionRoute('/home');
    }
  }

  isActiveRoute(target: string) {
    if (this.router.url === target) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy() { }
}
