import { Component, ElementRef, NgZone, ViewChild } from '@angular/core';
import { WaterfallTransitionService } from './global/waterfall-transition/waterfall-transition.service';

import { Subscription } from 'rxjs';
import { HomeStatesService } from './static/home-states.service';
import { NavigationEnd, Router } from '@angular/router';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Scrollbar from 'smooth-scrollbar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @ViewChild('viewWrapper') viewWrapper?: ElementRef;

  noScroll = true;
  prepareEasing = false;

  bodyScrollBar: any;
  subscriptions = new Subscription();

  constructor(
    private homeStatesService: HomeStatesService,
    private waterfallTransitionService: WaterfallTransitionService,
    private ngZone: NgZone,
    private router: Router
  ) {
    // Arm View Easing Subscription;
    this.subscriptions.add(
      this.waterfallTransitionService.armViewEasing$.subscribe(
        (easingPreparation: any) => {
          // Run this in Angular Zone;
          this.ngZone.run(() => {
            if (easingPreparation) {
              this.prepareEasing = easingPreparation;
              this.noScroll = true;
            } else {
              this.prepareEasing = easingPreparation;
              this.noScroll = false;
            }
          });
        }
      )
    );

    // Listen to page state;
    this.subscriptions.add(
      this.homeStatesService.homeHeroLaunch$.subscribe((e) => {
        // If hero is launched; we now allow scrolling;
        if (e) {
          this.noScroll = false;
        }
      })
    );

    // Listen to router-end and reset window;
    this.subscriptions.add(
      this.router.events.subscribe((e) => {
        if (e instanceof NavigationEnd) {
          this.resetScroll();
        }
      })
    );

  }

  ngOnInit() { }

  ngAfterViewInit() {
    // Register Scrolltrigger;
    gsap.registerPlugin(ScrollTrigger);

    // 3rd party library setup:
    const bodyScrollBar = Scrollbar.init(this.viewWrapper?.nativeElement, {
      damping: 0.05,
    });

    // Set this --- use for resetting later;
    this.bodyScrollBar = bodyScrollBar;

    // Tell ScrollTrigger to use these proxy getter/setter methods for the "body" element:
    ScrollTrigger.scrollerProxy(this.viewWrapper?.nativeElement, {
      scrollTop(value: any) {
        if (arguments.length) {
          bodyScrollBar.scrollTop = value; // setter
        }
        return bodyScrollBar.scrollTop; // getter
      },
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        };
      },
    });

    bodyScrollBar.addListener(ScrollTrigger.update);
  }

  resetScroll() {
    this.bodyScrollBar.scrollTop = 0;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
