import { ModuleWithProviders, NgModule } from '@angular/core';
import { HomeStatesService } from './home-states.service';
import { PageStatesService } from './page-states.service';

@NgModule({})
export class StaticModule {
  static forRoot(): ModuleWithProviders<StaticModule> {
    return {
      ngModule: StaticModule,
      providers: [PageStatesService, HomeStatesService],
    };
  }
}
