<div class="app">

  <app-curtain></app-curtain>

  <app-navigation></app-navigation>

  <div #viewWrapper class="view-wrapper" [ngClass]="{
      'prepare-easing': prepareEasing
    }">
    <router-outlet (activate)="resetScroll()"></router-outlet>
  </div>

</div>
