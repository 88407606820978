import { gsap } from 'gsap';

export class BrandingAnimations {
  flyInHome(el: HTMLElement, delay?: number, callback?: () => void) {
    /* Setter */
    gsap.set(el, {
      y: '21px',
      opacity: 0,
    });

    /* Animator */
    gsap.to(el, {
      y: '0px',
      ease: 'back.out(1.2)',
      duration: 1.2,
      opacity: 1,
      onComplete: callback,
    });
  }

  flyOutHomeFlyInBranding(homeEl: HTMLElement, lettersEl: HTMLElement) {
    // Home Animation;
    gsap.set(homeEl, {
      y: '0px',
      opacity: 1,
    });

    gsap.to(homeEl, {
      y: '-32px',
      duration: 0.6,
      opacity: 0,
    });

    /* Branding Animation */
    gsap.set(lettersEl.childNodes, {
      y: '21px',
      opacity: 0,
    });

    gsap.to(lettersEl.childNodes, {
      y: '-5px',
      opacity: 1,
      duration: 0.8,
      ease: 'back.out(1.2)',
      stagger: 0.15,
      delay: 0.2,
    });
  }

  flyOutBrandingFlyInHome(
    homeEl: HTMLElement,
    lettersEl: HTMLElement,
    delay?: number,
    callback?: () => void
  ) {
    let tl = gsap.timeline({
      delay: delay,
      onComplete: callback,
    });

    /* Branding Animation */
    gsap.set(lettersEl.childNodes, {
      y: '-5px',
      opacity: 1,
    });

    tl.to(lettersEl.childNodes, {
      y: '21px',
      opacity: 0,
      duration: 0.5,
      stagger: 0.09,
      ease: 'back.in(1)',
      delay: 0,
    });

    // Home Animation;
    gsap.set(homeEl, {
      y: '-32px',
      opacity: 0,
    });

    tl.to(
      homeEl,
      {
        y: '0px',
        duration: 0.6,
        opacity: 1,
      },
      '-=0.4'
    );
  }
}
