import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HomeStatesService {
  homeHeroLaunch$ = new Subject();
  rocketLaunch$ = new Subject();
  rocketSet$ = new Subject();

  pageStates = {
    homePage: {
      heroLaunched: false,
      rocketLaunched: false,
    },
  };

  constructor() {}

  launchRockets() {
    this.rocketLaunch$.next();
    this.pageStates.homePage.rocketLaunched = true;
  }

  setRockets() {
    this.rocketSet$.next();
    this.pageStates.homePage.rocketLaunched = true;
  }

  getLaunchRocketsState() {
    return this.pageStates.homePage.rocketLaunched;
  }

  launchHomeHero() {
    this.homeHeroLaunch$.next(true);
    this.pageStates.homePage.heroLaunched = true;
  }

  getLaunchHomeHero() {
    return this.pageStates.homePage.heroLaunched;
  }
}
