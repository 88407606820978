import { gsap } from 'gsap';

export class NavBodyAnimations {
  flyInNavLinks(el: HTMLElement, callback?: () => void, delay?: number) {
    let tl = gsap.timeline({
      delay: delay,
      onComplete: callback,
    });

    tl.set(el.children, {
      y: '32px',
      opacity: 0,
    });

    tl.to(el.children, {
      y: 0,
      opacity: 1,
      stagger: 0.08,
      duration: 0.6,
      delay: delay,
      ease: 'back.out(1)',
    });
  }

  flyOutNavLinks(el: HTMLElement, callback?: () => void, delay?: number) {
    let tl = gsap.timeline({
      delay: delay,
      onComplete: callback,
    });

    tl.set(el.children, {
      y: '0',
      opacity: 1,
    });

    tl.to(el.children, {
      y: '16px',
      opacity: 0,
      stagger: {
        each: 0.05,
        from: 'end',
      },
      duration: 0.5,
      delay: delay,
      ease: 'back.out(1)',
    });
  }

  forceOutNavLinks(el: HTMLElement) {
    gsap.set(el.children, {
      opacity: 0,
    });
  }

  flyInSocialLinks(el: HTMLElement, callback?: () => void, delay?: number) {
    let tl = gsap.timeline({
      delay: delay,
      onComplete: callback,
    });

    tl.set(el.children, {
      x: '32px',
      y: 0,
      opacity: 0,
      overwrite: true,
    });

    tl.to(el.children, {
      x: 0,
      y: 0,
      opacity: 1,
      stagger: 0.1,
      duration: 0.8,
      delay: delay,
      ease: 'back.out(1)',
    });
  }

  forceOutSocialLinks(el: HTMLElement) {
    gsap.set(el.children, {
      opacity: 0,
    });
  }

  flyOutSocialLinks(el: HTMLElement, callback?: () => void, delay?: number) {
    let tl = gsap.timeline({
      delay: delay,
      onComplete: callback,
    });

    tl.set(el.children, {
      y: '0',
      opacity: 1,
    });

    tl.to(el.children, {
      y: '16px',
      opacity: 0,
      stagger: {
        each: 0.05,
        from: 'end',
      },
      duration: 0.3,
    });
  }

  flyInNavfooter(el: HTMLElement, callback?: () => void, delay?: number) {
    let tl = gsap.timeline({
      delay: delay,
      onComplete: callback,
    });

    gsap.set(el, {
      y: '8px',
      opacity: 0,
      overwrite: true,
    });

    tl.to(el, {
      y: 0,
      opacity: 1,
      duration: 0.6,
      ease: 'back.out(1)',
    });
  }

  forceOutNavFooter(el: HTMLElement) {
    gsap.set(el, {
      opacity: 0,
    });
  }

  flyOutNavfooter(el: HTMLElement, callback?: () => void, delay?: number) {
    let tl = gsap.timeline({
      delay: delay,
      onComplete: callback,
    });

    tl.to(el, {
      y: '8px',
      opacity: 0,
      duration: 0.6,
      ease: 'back.out(1)',
    });
  }
}
